<template>
  <div class="notification customer-notification">
    <item-title :title="{name: '客户告知书', en_1: 'CUSTOMER', en_2: 'NOTIFICATION'}" />
    <div v-if="content" class="notification-content">
      <div class="border"></div>
      <div class="content" v-html="content">
        <!-- <div style="font-size: .3rem; padding: .6rem .64rem .25rem; background-color: #fbf9f9;" class="title">尊敬的客户：</div>
        <section style="padding: 0 .64rem; font-size: .18rem; background-color: #fbf9f9;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6;  border-top: 1px solid #E6E6E6; padding: .15rem 0;">感谢您选择我公司（明亚保险经纪股份有限公司）作为您的保险经纪人，为您办理保险业务。我公司是基于</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0;">投保人的利益，为投保人与保险公司订立保险合同提供中介服务的保险专业中介机构。为了保护您的合法权</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0;">益，按照《保险经纪人监管规定》的要求，我公司向您履行客户告知义务，现将有关事项告知如下。</p>
        </section>
        <section style="padding: 0 .64rem; margin-top: .55rem;  font-size: .18rem;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6;  border-top: 1px solid #E6E6E6; padding: .15rem 0;" class="label">一、公司就基本情况</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">1. 名称: 明亚保险经纪股份有限公司</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">2. 住所：北京市朝阳区朝外大街22号泛利大厦5层501-502室</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">3. 经营保险经纪业务许可证编号：260500000000800</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">4. 经营区域和业务范围：全国区域内（港、澳、台除外）为投保人拟定投保方案、选择保险人、办理投保 </p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">手续；协助被保险人或受益人进行索赔；再保险经纪业务；为委托人提供防灾、防损或风险评估、风险管</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">理咨询服务；中国保险监督管理委员会(现已更名为“中国银行保险监督管理委员会”)批准的其他业务。</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">5. 联系方式：<span style="color: #FE6917;">400-612-8688/400-811-0606</span></p>
        </section>
        <section style="padding: 0 .64rem; font-size: .18rem; margin-top: .55rem;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6;  border-top: 1px solid #E6E6E6; padding: .15rem 0;" class="label">二、请仔细阅读保险条款，重点关注保险责任、责任免除或除外责任、投保人和被保险人权利义务、</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">比例赔付或者给付、免赔额或免赔率的计算、犹豫期解除合同、退保损失、保险新型产品费用</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">扣除及投资风险、等待期等内容，并可要求我公司业务人员对上述内容进行详细讲解。</p>
        </section>
        <section style="padding: 0 .64rem; font-size: .18rem; margin-top: .55rem;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6;  border-top: 1px solid #E6E6E6; padding: .15rem 0;" class="label">三、请向我公司业务人员了解《中华人民共和国保险法》等法律法规对于索赔时效、保险公司理赔时限、</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0;  padding-left: .35rem;"> 合同中止与失效等的相关规定，以及不履行如实告知义务、故意制造保险事故或夸大事故损失、申</p>
           <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0;  padding-left: .35rem;">报年龄不真实等情形导致的法律后果。 </p>
        </section>
        <section style="padding: 0 .64rem; font-size: .18rem; margin-top: .55rem;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; border-top: 1px solid #E6E6E6;  padding: .15rem 0;" class="label">四、为保证对您的服务品质，我公司与保险公司协商，向保险公司收取佣金或向客户收取服务费作为报酬。</p>
        </section>
        <section style="padding: 0 .64rem; font-size: .18rem; margin-top: .55rem;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6;  border-top: 1px solid #E6E6E6; padding: .15rem 0;" class="label">五、关联关系说明：我公司及高级管理人员与经纪业务相关的保险公司、其他保险中介机构不存在</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;" class="label">关联关系。</p>
        </section>
        <section style="padding: 0 .64rem; font-size: .18rem; margin-top: .55rem;" class="section">
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6;  border-top: 1px solid #E6E6E6;  padding: .15rem 0;">六、如果您发现我公司业务人员存在误导行为及其他损害您合法权益的行为，请注意保留书面证据或</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">其他证据，可向我公司投诉<span style="color: #FE6917;">（电话： 400-612-8688/400-811-0606）</span>反应</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">若您与我公司发生纠纷，可按照《保险经纪服务委托协议》的约定，通过向我公司所在地有管辖</p>
          <p style="margin-bottom: 0; border-bottom: 1px solid #E6E6E6; padding: .15rem 0; padding-left: .35rem;">权的人民法院提起诉讼，解决纠纷。</p>
        </section> -->

      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import { getNotification } from '@/api/customerService'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      content: ''
    }
  },
  created() {
    // console.log(this.html2Escape(this.test))
    // console.log(this.escape2Html(this.html2Escape(this.test)))
    this.getData()
  },
  methods: {
    // 获取告知书页面数据
    async getData() {
      const res = await getNotification('customer notification')
      this.content = this.escape2Html(res.list[0].content)
      this.$emit('loading', false)
    },
    html2Escape(str) {
      return str.replace(/[<>&"]/g, function (c) {
        return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c]
      })
    },
    escape2Html(str) {
      var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    }
  }
}
</script>
<style lang="scss">
.customer-notification {
  p {
    margin-bottom: 0;
    text-align: justify;
  }
  // .content {
  //   p {
  //     margin-bottom: 0;
  //   }
  //   .title {
  //     font-size: 0.3rem;
  //     padding: 0.6rem 0.64rem 0.25rem;
  //     background-color: #fbf9f9;
  //   }
  //   section {
  //     padding: 0 0.64rem;
  //     font-size: 0.18rem;
  //     margin-bottom: 0.55rem;
  //     &:nth-child(2) {
  //       background-color: #fbf9f9;
  //     }
  //     &.bg-color {
  //       background-color: #fbf9f9;
  //     }
  //     .color {
  //       color: #fe6917;
  //     }
  //     p {
  //       margin-bottom: 0;
  //       border-bottom: 1px solid #e6e6e6;
  //       padding: 0.15rem 0;
  //     }
  //     .label {
  //       border-top: 1px solid #e6e6e6;
  //     }
  //     .indent {
  //       padding-left: 0.35rem;
  //     }
  //     .indent1 {
  //       padding-left: 0.2rem;
  //     }
  //   }
  // }
  @media only screen and (max-width: $moble_width) {
    .notification-content {
      .content > div > div {
        padding: 20px 5px 0!important;
        background-color: #faf8f7;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.notification-content {
  margin-top: 0.7rem;
  .content {
    background-image: url(../../assets/img/customerService/notification_bg.png);
    background-size: 100% auto;
    background-position: left bottom;
    background-repeat: no-repeat;
    // padding-bottom:20px!important;
  }
}
.border {
  width: 100%;
  height: 4px;
  background-image: url(../../assets/img/customerService/border_line.png);
  background-size: 100% 100%;
}
@media only screen and (max-width: $moble_width) {
  .notification-content {
    .content > div > div {
      padding: 20px 0 0!important;
    }
  }
}
</style>